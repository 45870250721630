<template>
  <div class="progress-bar" :style="style">
    <div class="progress-bar--filled"></div>
  </div>
</template>

<script setup lang="ts">
import type { IProgressBarProps } from './ProgressBar.types';

const props = defineProps<IProgressBarProps>();
const { current, max } = toRefs(props);

const style = computed(() => ({
  '--filled-width': `${(current.value / max.value) * 100}%`,
}));
</script>

<style scoped lang="scss" src="./ProgressBar.scss" />
