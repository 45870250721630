<template>
  <section class="game-controller" :style="currentRotation" @click="handleGameClick">
    <div class="game-controller__inner"></div>
    <NuxtImg class="game-controller__image" :src="hamsterImg" provider="localProvider" />
    <div class="game-controller__clicks">
      <TransitionGroup name="hamster-fade" @after-enter="handleAfterEnter">
        <span
          v-for="item in clicksItems"
          :id="item.id"
          :key="item.id"
          class="game-controller__clicks-item"
          :style="clickStyle(item)"
        >
          +25
        </span>
      </TransitionGroup>
    </div>
  </section>
</template>

<script setup lang="ts">
import { type IClick, ROTATION_MATRIX } from './GameController.data';
import type { TPossibleNull } from '~/types/Shared.types';
import { useGameStore } from '~/features/hamster/store/game.store';

let rotationTimeout: TPossibleNull<ReturnType<typeof setTimeout>> = null;

const gameStore = useGameStore();
const { clicks } = storeToRefs(gameStore);

const hamsterImg = computed(() => getS3Image('hamster', 'hamster'));

const clicksItems = ref<IClick[]>([]);
const currentRotation = ref({
  '--angle-x': 0,
  '--angle-y': 0,
});

const clickStyle = computed(() => (item: IClick) => ({
  '--x': `${item.x}px`,
  '--y': `${item.y}px`,
}));

const handleRotationAnimation = (x: number, y: number) => {
  if (rotationTimeout) clearTimeout(rotationTimeout);

  const isInRange = (n: number, range: number[]) => n >= range[0] && n <= range[1];
  const matrix = ROTATION_MATRIX.find((item) => isInRange(x, item.x) && isInRange(y, item.y))?.matrix;

  if (!matrix) return;

  currentRotation.value = {
    '--angle-x': matrix[0],
    '--angle-y': matrix[1],
  };

  rotationTimeout = setTimeout(() => {
    rotationTimeout = null;
    currentRotation.value = {
      '--angle-x': 0,
      '--angle-y': 0,
    };
  }, 500);
};

const handleGameClick = (e: MouseEvent) => {
  const { offsetX: x, offsetY: y } = e;
  const id = `${clicks.value++}`;

  handleRotationAnimation(x, y);

  clicksItems.value.push({ id, x, y });
};

const handleAfterEnter = (el: Element) => {
  const id = el.id;
  const index = clicksItems.value.findIndex((item) => item.id === id);

  if (index === -1) return;
  clicksItems.value.splice(index, 1);
};
</script>

<style lang="scss">
.hamster-fade-enter-active {
  animation: hamster-fade-frames 1s linear forwards;
}

.hamster-fade-leave-from {
  opacity: 0;
  transform: translateY(-100px);
}

@keyframes hamster-fade-frames {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}
</style>

<style scoped lang="scss" src="./GameController.scss" />
