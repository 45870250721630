<template>
  <section class="content-controller">
    <div class="content-controller__main">
      <HamsterRewardsController />
      <HamsterRublesController />
      <HamsterGameController />
      <span class="content-controller__main-info">{{ $t('lostLocales.hamster') }}</span>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss" src="./ContentController.scss" />
