<template>
  <section class="rewards-controller">
    <HamsterRewardItem v-for="reward in rewards" :key="reward.title" class="rewards-controller__item" v-bind="reward" />
  </section>
</template>

<script setup lang="ts">
const rewards = [
  {
    amount: 1,
    title: 'Mil-Spec скин',
  },
  {
    amount: 35,
    title: 'Токены на баланс',
  },
  {
    amount: 1,
    title: 'Кейс бесплатно',
  },
];
</script>

<style scoped lang="scss" src="/features/hamster/controllers/RewardsController/RewardsController.scss" />
