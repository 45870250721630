<template>
  <div class="reward-item">
    <span class="reward-item__title">{{ title }}</span>
    <span class="reward-item__amount">{{ plusText }}</span>
  </div>
</template>

<script setup lang="ts">
import type { IRewardItemProps } from './RewardItem.types';

const props = defineProps<IRewardItemProps>();
const { amount } = toRefs(props);

const plusText = computed(() => `+${amount.value}`);
</script>

<style scoped lang="scss" src="./RewardItem.scss" />
