<template>
  <section class="rubles-controller">
    <HamsterRublesAmount :amount="rubles" />
    <HamsterProgressBar :current="rubles" :max="MAX_AMOUNT" />
  </section>
</template>

<script setup lang="ts">
import { useGameStore, MAX_AMOUNT } from '~/features/hamster/store/game.store';

const gameStore = useGameStore();
const { rubles } = storeToRefs(gameStore);
</script>

<style scoped lang="scss" src="./RublesController.scss" />
