export interface IClick {
  id: string;
  x: number;
  y: number;
}

export const ROTATION_MATRIX = [
  {
    matrix: [1, -1],
    x: [0, 140],
    y: [0, 140],
  },
  {
    matrix: [1, 1],
    x: [140, 280],
    y: [0, 140],
  },
  {
    matrix: [-1, 1],
    x: [140, 280],
    y: [140, 280],
  },
  {
    matrix: [-1, -1],
    x: [0, 140],
    y: [140, 280],
  },
];
